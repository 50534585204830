<template>
  <b-card title="Thông tin danh mục">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tên danh mục"
            label-cols-md="2"
            label-for="category-name"
          >
            <b-form-input
              id="category-name"
              placeholder="Quả ngắn ngày"
              v-model="categoryData.name"
              :class="{ 'is-invalid' : isInvalid(categoryData.name)}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1"
          type="submit"
          variant="primary"
        >
          Lưu thông tin
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {BButton, BRow, BCol, BCard, BForm, BFormInput, BFormGroup} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "../../../../@core/components/toastification/ToastificationContent";

export default {
  name: "CategoryAdd",
  components: {BButton, BRow, BCol, BCard, BForm, BFormInput, BFormGroup},
  directives: {
    Ripple
  },
  data() {
    return {
      categoryData: {
        name: ''
      },
      isValidate: false
    }
  },
  methods: {
    isInvalid (val) {
      return this.isValidate && val.length === 0
    },
    async handleSubmit() {
      this.isValidate = true
      if (!this.isInvalid(this.categoryData.name)) {
        this.$http.post('/categories', this.categoryData)
            .then(response => {
              console.log(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Cập nhật thành công👋',
                  variant: 'success',
                },
              })
              this.$router.push('/manage/category/list')
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                  variant: 'danger',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
